@import '../mediaQueries.scss';
.case-quote {
  margin: var(--padding--regular);
  app-text-block {
    padding: var(--padding--medium);
    blockquote {
      font-size: var(--font-size--body-extra-large);
      font-weight: 700;
      p {
        line-height: initial;
      }
    }
    a {
      color: #fff;
    }
  }
  app-image-block {
    picture {
      img {
        display: block;
      }
    }
  }

  @include mobile {
    margin: 0;
  }
}
