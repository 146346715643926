@import '../mediaQueries.scss';

.compensate-plus-grid {
  grid-template-columns:
    1fr auto minmax(50px, 100px) auto var(--padding--regular)
    1fr;
  grid-template-rows: max-content 1fr minmax(100px, min-content) 1fr;
  grid-template-areas:
    'heading heading heading heading heading heading'
    '. . . t1 t1 t1'
    '. . . . . t2'
    't3 t3 . . . t2';
  margin: var(--padding--regular);
  padding: var(--padding--large) var(--padding--medium);
  app-text-block {
    &:nth-of-type(1) {
      grid-area: heading;
      align-self: center;
      text-align: center;
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 0;
      padding-bottom: var(--padding--large);
      * {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    &:nth-of-type(2) {
      grid-area: t1;
      max-width: 400px;
      justify-self: start;
      padding-left: var(--padding--medium);
      padding-right: 0;
    }
    &:nth-of-type(3) {
      grid-area: t2;
      max-width: 400px;
      justify-self: start;
      padding-left: 0;
      padding-right: 0;
    }
    &:nth-of-type(4) {
      grid-area: t3;
      max-width: 600px;
      text-align: right;
      justify-self: end;
      align-self: flex-end;
      padding-right: var(--padding--medium);
      padding-left: 0;
      padding-bottom: 0;
    }
  }
  app-image-block {
    width: 25vw;
    height: 35vw;
    &:nth-of-type(1) {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 4;
      justify-self: end;
    }
    &:nth-of-type(2) {
      justify-self: start;
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row-start: 3;
      grid-row-end: 5;
    }
  }
  @include mobile {
    margin: 0;
    padding: var(--padding--regular);
    grid-template-columns: auto;
    grid-template-rows: max-content max-content 50vh max-content 50vh max-content;
    grid-template-areas: 'heading' 't1' 'i1' 't2' 'i2' 't3';
    gap: var(--padding--regular);
    app-text-block {
      text-align: left !important;
      padding: 0 !important;
      &:nth-of-type(1) * {
        color: var(--colors--cyan);
      }

      h2,
      h3 {
        margin-top: 0;
      }
    }
    app-image-block {
      width: 100%;
      height: 100%;
      &:nth-of-type(1) {
        grid-area: i1;
      }
      &:nth-of-type(2) {
        grid-area: i2;
      }
    }
  }
}
