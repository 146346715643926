$mobile-max-width-start: 900px;
$tablet-max-width-start: 1300px;
$desktop-small-max-width-start: 1700px;

@mixin desktop {
  @media (min-width: #{$tablet-max-width-start}) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width: #{$desktop-small-max-width-start}) {
    @content;
  }
}

@mixin desktop-small {
  @media (max-width: #{$desktop-small-max-width-start}) {
    @content;
  }
}

@mixin desktop-medium-height {
  @media (max-height: 900px) {
    @content;
  }
}

@mixin desktop-small-height {
  @media (max-height: 700px) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: #{$mobile-max-width-start}) and (max-width: #{$tablet-max-width-start}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-max-width-start}) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: #{$mobile-max-width-start}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$mobile-max-width-start}) {
    @content;
  }
}

@mixin mobile-or-portrait {
  @media (max-width: #{$mobile-max-width-start}), (orientation: portrait) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}
