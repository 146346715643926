@import '../mediaQueries.scss';

.contact-us-intro {
  display: grid;
  grid-template-areas: 'top-left top-right' 'top-left bottom-right' 'bottom-left bottom-right' 'bottom-left .';
  grid-template-columns: 2fr 1.3fr;
  grid-template-rows:
    min-content var(--padding--extra-large) 400px
    max-content;
  gap: var(--padding--regular) var(--padding--large);
  background: #f0f2f2;
  padding: var(--padding--large) 0;
  margin: var(--padding--regular);
  & > app-text-block {
    &:nth-child(1) {
      grid-area: top-left;
      padding-left: var(--padding--large);
      h4 {
        margin: 0;
      }
      a {
        font-size: var(--font-size--body-large);
        color: #000;
        margin: 0;
      }
      p {
        margin-top: 0;
      }
    }
    &:nth-child(2) {
      grid-area: top-right;
      padding-right: var(--padding--large);
      padding-left: 0 !important;
      h2 {
        margin: 0;
        font-size: var(--font-size--body-large);
        &:nth-child(2) {
          color: var(--colors--cyan);
          margin-bottom: var(--padding--extra-large);
        }
      }
      p {
        color: #969696;
        margin: 0;
      }
    }
  }

  & > app-image-block {
    grid-area: bottom-right;
    width: 80%;
    max-width: 440px;
    height: 100%;
  }

  & > app-container-block {
    grid-area: bottom-left;
    padding-left: var(--padding--large);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--padding--regular);
    app-text-block {
      padding: 0 !important;
      p,
      a {
        margin: 0;
      }
      p {
        color: var(--colors--cyan);
      }
      a {
        color: #000;
      }
    }
  }

  @include mobile {
    grid-template-areas: 'top-left' 'top-right' 'bottom-left' 'bottom-right';
    grid-template-columns: auto;
    grid-template-rows: max-content max-content max-content max-content;
    gap: var(--padding--small);
    margin: 0;
    & > app-text-block {
      margin: 0 !important;
      padding: var(--padding--tiny) var(--padding--regular) !important;
      &:nth-child(2) {
        padding-left: var(--padding--regular) !important;
        padding-right: var(--padding--regular) !important;
      }
    }
    & > app-image-block {
      width: 100%;
    }

    & > app-container-block {
      padding-left: var(--padding--regular);
      grid-template-columns: auto;
      gap: var(--padding--tiny);
    }
  }
}
