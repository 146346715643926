@import '../mediaQueries.scss';

.columns-3 {
  display: grid;
  padding: var(--padding--large) 0;
  margin: var(--padding--regular);
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--padding--large);
  app-text-block {
    max-width: 760px;
    padding: 0;
    h2 {
      margin: 0 0 var(--padding--regular);
    }
    h3 {
      font-size: var(--font-size--heading-small);
      margin: var(--padding--regular) 0;
      color: var(--colors--cyan);
    }
    a {
      color: #000;
      display: block;
      text-decoration: none;
      margin: var(--padding--tiny) 0;
      font-weight: 700;
    }
  }
  app-image-block,
  app-video-block {
    height: 80%;
    &.align-top {
      align-self: start;
    }
    &.align-bottom {
      align-self: end;
    }
    &:nth-child(1) {
      margin-right: var(--padding--medium);
    }
    &:nth-child(3) {
      margin-left: var(--padding--medium);
    }
  }
  .wide-center {
    grid-template-columns: 1fr 1.5fr 1fr !important;
    *:nth-child(1) {
      margin: var(--padding--regular) var(--padding--medium)
        var(--padding--regular) var(--padding--large);
    }
    *:nth-child(3) {
      margin: var(--padding--regular) var(--padding--large)
        var(--padding--regular) var(--padding--medium);
    }
  }
  @include mobile {
    gap: var(--padding--small) 0;
    grid-template-columns: auto !important;
    app-image-block,
    app-video-block {
      height: 100%;
    }
  }
}
