@import '../mediaQueries.scss';

.what-you-get {
  margin: 0 var(--padding--regular) var(--padding--regular);
  padding: var(--padding--large);
  grid-template-columns: var(--padding--large) max-content var(--padding--large) max-content var(
      --padding--large
    );
  grid-template-rows:
    max-content var(--padding--medium) max-content var(--padding--large)
    max-content;
  justify-content: center;
  & > app-text-block:first-child {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 6;
    text-align: center;
  }
  .results-section:first-of-type {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 2;
    grid-column-end: 6;
    grid-template-columns: minmax(25vw, 560px) 1fr;
    justify-self: start;
  }
  .results-section:last-of-type {
    grid-row-start: 4;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-template-columns: 1fr minmax(25vw, 560px);
    text-align: right;
    justify-self: end;
  }
  .results-section {
    align-items: center;
    gap: var(--padding--regular);
    h4 {
      font-size: var(--font-size--heading-small);
      margin: var(--padding--medium) 0 0;
    }
    p,
    strong {
      font-size: var(--font-size--heading-hero);
      margin: 0 0 var(--padding--medium);
    }
  }

  @include mobile {
    margin: 0;
    padding: 0;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content;
    .results-section:first-of-type {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .results-section:last-of-type {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .results-section {
      gap: 0;
      grid-template-columns: 1fr 1fr !important;
      app-image-block {
        height: 100%;
      }
      app-video-block {
        height: 100%;
      }
      app-text-block {
        padding: var(--padding--small);
        h4 {
          font-size: var(--font-size--heading-small);
        }
      }
    }
  }
}
