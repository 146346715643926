@import '../mediaQueries.scss';

.results-sections {
  margin: 0 var(--padding--regular) var(--padding--regular);
  padding: var(--padding--large);
  grid-template-columns: var(--padding--large) 1fr var(--padding--large) 1fr var(
      --padding--large
    );
  grid-template-rows:
    max-content var(--padding--medium) max-content var(--padding--large)
    max-content;
  justify-content: center;
  max-width: 100%;
  & > app-text-block:first-child {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 6;
    text-align: center;
    padding: 0;
  }
  .results-section:first-of-type {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 2;
    grid-column-end: 6;
    grid-template-columns: minmax(25vw, 560px) 1fr;
    justify-self: start;
    align-items: start;
  }
  .results-section:last-of-type {
    grid-row-start: 4;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-template-columns: 1fr minmax(25vw, 560px);
    text-align: right;
    justify-self: end;
  }
  .results-section {
    align-items: center;
    gap: var(--padding--regular);
    white-space: pre-line;
    h5 {
      font-size: var(--font-size--heading-small);
      margin: 0;
    }
    p,
    strong {
      font-size: var(--font-size--heading-hero);
      margin: 0;
      line-height: 100%;
    }
    app-video-block {
      video {
        height: 75vh;
        object-fit: cover;
      }
    }
  }

  @include tablet {
    margin: 0;
    padding: 0;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content max-content;
    & > app-text-block:first-child {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 6;
      text-align: center;
      padding: var(--padding--regular) 0;
    }
    .results-section:first-of-type {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 2;
      align-items: center;
    }
    .results-section:last-of-type {
      grid-row-start: 3;
      grid-row-end: 4;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .results-section {
      gap: 0;
      grid-template-columns: 1fr 1fr !important;
      app-image-block {
        height: 100%;
      }
      app-video-block {
        height: 100%;
      }
      app-text-block {
        padding: var(--padding--small);
      }
      h5 {
        margin: var(--padding--regular) var(--padding--small) 0;
      }
      p {
        margin: 0 var(--padding--small) var(--padding--small);
      }
    }
  }
  @include mobile {
    .results-section {
      p {
        margin: var(--padding--tiny) 0 0;
        strong {
          margin-top: 10px;
          font-size: 1.5rem !important;
          line-height: 100%;
        }
      }
      h5 {
        margin: 0 0 var(--padding--tiny);
        font-size: 1rem;
      }
    }
  }
}
