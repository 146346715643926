@import './mediaQueries.scss';

:root {
  --colors--cyan: #288c96;
  --colors--cyan-light: #e5f1f2;
  --colors--cyan-extra-light: #f2f8f9;
  --colors--dark: #1b1b1b;
  --colors--grey: #eee;
  --colors--error: #7d152a;
  --colors--radical-red: #f94460;

  --padding--tiny: 10px;
  --padding--small: 20px;
  --padding--regular: 40px;
  --padding--medium: 80px;
  --padding--large: 120px;
  --padding--extra-large: 160px;
  --padding--super-large: 220px;

  font-size: 20px;
  --font-size--body-small: 16px;
  --font-size--body-large: 30px;
  --font-size--body-extra-large: 38px;
  --font-size--heading-tiny: 22px;
  --font-size--heading-small: 30px;
  --font-size--heading-cards: 40px;
  --font-size--heading-regular: 40px;
  --font-size--heading-large: 54px;
  --font-size--navigation-item: 48px;
  --font-size--heading-hero: 64px;

  --font-size--button-regular: 1rem;
  --font-size--button-large: 1.1rem;

  @include desktop-small {
    --padding--regular: 30px;
    --padding--medium: 60px;
    --padding--large: 90px;
    --padding--extra-large: 120px;
    --padding--super-large: 180px;

    font-size: 18px;
    --font-size--body-large: 26px;
    --font-size--body-extra-large: 32px;

    --font-size--heading-small: 28px;
    --font-size--heading-cards: 42px;
    --font-size--heading-regular: 36px;
    --font-size--navigation-item: 40px;
    --font-size--heading-large: 52px;
    --font-size--heading-hero: 60px;
  }

  @include tablet {
    --padding--regular: 25px;
    --padding--medium: 50px;
    --padding--large: 75px;
    --padding--extra-large: 100px;
    --padding--super-large: 100px;
    font-size: 16px;
    --font-size--body-small: 16px;
    --font-size--body-large: 26px;
    --font-size--body-extra-large: 30px;

    --font-size--heading-small: 26px;
    --font-size--heading-cards: 36px;
    --font-size--heading-regular: 30px;
    --font-size--heading-large: 42px;
    --font-size--navigation-item: 40px;
    --font-size--heading-hero: 52px;
  }

  @include desktop-medium-height {
    --font-size--navigation-item: 40px;
  }

  @include desktop-small-height {
    --padding--tiny: 10px;
    --padding--small: 10px;
    --padding--regular: 20px;
    --padding--medium: 40px;
    --padding--large: 60px;
    --padding--extra-large: 80px;

    font-size: 16px;
    --font-size--body-small: 14px;
    --font-size--body-large: 20px;
    --font-size--body-extra-large: 28px;
    --font-size--heading-tiny: 18px;
    --font-size--heading-small: 20px;
    --font-size--heading-cards: 32px;
    --font-size--heading-regular: 24px;
    --font-size--heading-large: 42px;
    --font-size--navigation-item: 32px;
    --font-size--heading-hero: 52px;

    --font-size--button-regular: 1rem;
    --font-size--button-large: 1.1rem;
  }
  @include mobile {
    --padding--tiny: 5vw;
    --padding--small: 7.5vw;
    --padding--regular: 10vw;
    --padding--medium: 12.5vw;
    --padding--large: 15vw;
    --padding--extra-large: 20vw;

    font-size: 14px;
    --font-size--body-small: 14px;
    --font-size--body-large: 16px;
    --font-size--body-extra-large: 20px;
    --font-size--heading-tiny: 18px;
    --font-size--heading-small: 20px;
    --font-size--heading-cards: 28px;
    --font-size--heading-regular: 24px;
    --font-size--heading-large: 32px;
    --font-size--navigation-item: 24px;
    --font-size--heading-hero: 44px;

    --font-size--button-regular: 1rem;
    --font-size--button-large: 1.1rem;
  }
}
