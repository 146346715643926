@import '../mixins.scss';
@import '../mediaQueries.scss';

.margin-none {
  margin: 0 !important;
}
.tight-headings {
  h1,
  h2,
  h3,
  h4 {
    margin-top: var(--padding--tiny);
    margin-bottom: var(--padding--tiny);
  }
}
/* Padding none */
.padding-top-none {
  padding-top: 0 !important;
}
.padding-none {
  padding: 0 !important;
}
.padding-bottom-none {
  padding-bottom: 0 !important;
}
.padding-left-none {
  padding-left: 0 !important;
}
.padding-right-none {
  padding-right: 0 !important;
}
@include mobile {
  .margin-top-none--mobile {
    margin-top: 0 !important;
  }
  .margin-none--mobile {
    margin: 0 !important;
  }
  .margin-bottom-none--mobile {
    margin-bottom: 0 !important;
  }
  .margin-left-none--mobile {
    margin-left: 0 !important;
  }
  .margin-right-none--mobile {
    margin-right: 0 !important;
  }
  .padding-top-none--mobile {
    padding-top: 0 !important;
  }
  .padding-none--mobile {
    padding: 0 !important;
  }
  .padding-bottom-none--mobile {
    padding-bottom: 0 !important;
  }
  .padding-left-none--mobile {
    padding-left: 0 !important;
  }
  .padding-right-none--mobile {
    padding-right: 0 !important;
  }
}
/* Padding regular */
.padding-top-regular {
  padding-top: var(--padding--regular) !important;
}
.padding-regular {
  padding: var(--padding--regular) !important;
}
.padding-bottom-regular {
  padding-bottom: var(--padding--regular) !important;
}

.padding-left-regular {
  padding-left: var(--padding--regular) !important;
}
.padding-right-regular {
  padding-right: var(--padding--regular) !important;
}

/* Padding medium */
.padding-top-medium {
  padding-top: var(--padding--medium) !important;
}
.padding-medium {
  padding: var(--padding--medium) !important;
}
.padding-bottom-medium {
  padding-bottom: var(--padding--medium) !important;
}
@include not-mobile {
  .padding-left-medium {
    padding-left: var(--padding--medium) !important;
  }
  .padding-right-medium {
    padding-right: var(--padding--medium) !important;
  }
}
/* Padding large */
.padding-top-large {
  padding-top: var(--padding--large) !important;
}
.padding-large {
  padding: var(--padding--large) !important;
}
.padding-bottom-large {
  padding-bottom: var(--padding--large) !important;
}
@include not-mobile {
  .padding-left-large {
    padding-left: var(--padding--large) !important;
  }
  .padding-right-large {
    padding-right: var(--padding--large) !important;
  }
}
/* Padding extra-large */
.padding-top-extra-large {
  padding-top: var(--padding--extra-large) !important;
}
.padding-extra-large {
  padding: var(--padding--extra-large) !important;
}
.padding-bottom-extra-large {
  padding-bottom: var(--padding--extra-large) !important;
}
@include not-mobile {
  .padding-left-extra-large {
    padding-left: var(--padding--extra-large) !important;
  }
  .padding-right-extra-large {
    padding-right: var(--padding--extra-large) !important;
  }
}
.text-white {
  color: #fff;
  a {
    color: #fff !important;
  }
  ul {
    li {
      list-style-image: url('data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2217%22%20viewBox%3D%220%200%2018%2017%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M8.15094%201L16%208.5M16%208.5L8.15094%2016M16%208.5L1.01327e-06%208.5%22%20stroke%3D%22white%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    }
  }
}
.text-black {
  color: #000;
}
.text-cyan {
  color: var(--colors--cyan);
}
.text-align-right {
  text-align: right !important;
}
.text-align-reset {
  text-align: initial !important;
}
.text-align-center,
.text-center {
  text-align: center;
  margin-left: auto !important;
  margin-right: auto !important;

  /**
  * Legacy usage of .text-center enforces an annoyingly stupid top and bottom margin of --padding-regular,
  * all new applications of centered text should also include .text-center--new e.g. <h4 class="text-center text-center--new">
  *
  * To avoid inadvertently removing vertical space applied by this annoyingly stupid style to legacy elements,
  * legacy styles are used if the --new suffix class isn't added.
  *
  * @todo upon testing all legacy elements, remove the following annoyingly stupid legacy styles
  */
  &:not(.text-center--new) {
    margin: var(--padding--regular) auto !important;
  }

  app-dynamic-link {
    display: block;
    width: fit-content;
    margin: auto;
    text-align: center;
  }
}

.heading-tiny {
  h1,
  h2,
  h3,
  h4 {
    font-size: var(--font-size--heading-tiny);
    line-height: 150%;
  }
}

h1,
h2,
h3,
h4 {
  &.heading-tiny {
    font-size: var(--font-size--heading-tiny);
    line-height: 150%;
  }
}

.background-cyan {
  background: var(--colors--cyan);
  color: #fff;
}
.background-dark {
  background: var(--colors--dark);
  color: #fff;
  a {
    color: #fff !important;
  }
}
.background-extra-light-grey {
  background: rgba(240, 242, 242, 0.5) !important;
}
.background-light-grey {
  background: #f0f2f2 !important;
}
.background-extra-light-cyan {
  background: rgba(40, 140, 150, 0.06) !important;
}
.background-light-cyan {
  background: rgba(40, 140, 150, 0.12) !important;
}
.background-extra-light-brown {
  background: #fcfaf8 !important;
}
.background-light-brown {
  background: rgba(160, 130, 112, 0.12) !important;
}

.sticky {
  height: fit-content;
  position: sticky;
  top: 15vh;
  bottom: 15vh;
  @include mobile {
    position: initial;
  }
}

.row-3-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--padding--regular);
}

.max-width-tiny {
  max-width: 520px;
}
.max-width-small {
  max-width: 640px;
}
.max-width-regular {
  max-width: 720px;
}
.max-width-medium {
  max-width: 840px;
}
.max-width-large {
  max-width: 960px;
}
.max-width-extra-large {
  max-width: 1100px;
}

.text-padding-left {
  section {
    padding-left: var(--padding--extra-large);
    @include mobile {
      padding-left: 0;
    }
  }
}
.text-padding-right {
  section {
    padding-right: var(--padding--large);
    @include mobile {
      padding-right: 0;
    }
  }
}

.vertical-align-center {
  align-items: center;
  & > * {
    height: fit-content;
  }
}

.open-positions {
  app-text-block {
    &:not(:first-of-type) {
      transition: 300ms ease-out;
      &:hover {
        opacity: 0.7;
        transform: translateX(8px);
      }
      a {
        border: none !important;
        display: flex !important;
        align-items: center;
        padding: 5px 0 !important;
        margin: var(--padding--tiny) 0 0 !important;
        font-size: var(--font-size--body-large);
        cursor: pointer;
        &::before {
          content: url(/assets/icons/arrow-black-to-right.svg);
          min-width: 42px;
          margin-right: 20px;
          display: block;
          line-height: 0;
          margin-top: -2px;
        }
      }
      p {
        margin-top: 0;
        margin-left: 62px;
      }
    }
  }
  @include mobile {
    padding: var(--padding--regular) !important;
    & > app-text-block {
      padding: 0 0 var(--padding--small) 0;
    }
  }
}

section {
  app-dynamic-link:first-child {
    a {
      margin-top: 0;
    }
  }
}

.no-text-margin {
  p {
    margin: 0;
  }
}

.fix-link-line-breaks {
  a {
    display: block;
  }
}
