@font-face {
  font-family: 'Circular';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Circular Std Book'), local('Circular-Std-Book'),
    url(/assets/fonts/CircularStd-Book.otf) format('opentype');
}
@font-face {
  font-family: 'Circular';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('CircularStd-Bold'), local('CircularStd-Bold'),
    url(/assets/fonts/CircularStd-Bold.otf) format('opentype');
}
