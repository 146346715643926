@import '../mediaQueries.scss';
.media-background {
  position: relative;
  margin: var(--padding--regular);
  background: var(--colors--dark);
  app-image-block,
  app-video-block {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  app-text-block {
    z-index: 1;
    padding: var(--padding--regular) var(--padding--extra-large)
      var(--padding--extra-large) var(--padding--large);
    h2,
    h3 {
      font-size: var(--font-size--heading-hero);
    }
    p {
      max-width: 420px;
      font-size: var(--font-size--body-small);
    }
  }
  @include mobile {
    margin: 0;
  }
}
