@import '../mediaQueries.scss';

.about-us-intro {
  display: grid;
  grid-template-areas: 'top-left top-right' 'bottom-left bottom-right';
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: max-content max-content;
  gap: var(--padding--small) var(--padding--large);
  background: #f0f2f2;
  padding: var(--padding--large) 0;
  margin: var(--padding--regular);
  .top-left {
    grid-area: top-left;
  }
  .top-right {
    grid-area: top-right;
  }
  .bottom-left {
    grid-area: bottom-left;
  }
  .bottom-right {
    grid-area: bottom-right;
  }

  app-text-block {
    max-width: 760px;
    &:nth-child(odd) {
      margin: var(--padding--regular) var(--padding--medium)
        var(--padding--regular) var(--padding--large);
    }
    &:nth-child(even) {
      margin: var(--padding--regular) var(--padding--large)
        var(--padding--regular) var(--padding--medium);
    }
    &:last-child {
      margin-left: 0;
      padding-left: 0;
    }
    blockquote {
      margin: 0;
      font-size: var(--font-size--heading-regular);
      color: var(--colors--cyan);
      font-weight: 700;
      p {
        line-height: initial;
      }
    }
    h3 {
      font-size: var(--font-size--heading-small);
    }
  }

  app-video-block {
    iframe {
      width: calc(100% - var(--padding--medium));
    }
  }

  @include mobile {
    grid-template-areas: 'top-left' 'top-right' 'bottom-left' 'bottom-right';
    grid-template-columns: auto;
    grid-template-rows: max-content max-content max-content max-content;
    gap: 0;
    margin: 0;
    app-text-block {
      margin: 0 !important;
      padding: 10px var(--padding--regular) !important;
      blockquote {
        p {
          margin-bottom: 0;
        }
      }
    }
    app-video-block {
      iframe {
        width: calc(100%);
      }
    }
  }
}
