@import '../mixins.scss';
@import '../mediaQueries.scss';

.reasons-section {
  padding: var(--padding--regular) calc(var(--padding--regular) * 4);
  grid-template-areas: 'heading heading heading' 'reason-1 reason-2 reason-3';
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: max-content max-content;
  gap: var(--padding--medium) var(--padding--regular);
  & > app-text-block {
    grid-area: heading;
    h2 {
      font-size: var(--font-size--heading-regular);
      text-align: center;
      margin: 0;
    }
  }
  & > app-container-block {
    height: min-content;
    app-image-block {
      max-width: 200px;
      height: 200px;
    }
    app-text-block {
      padding-top: 0;
      h2 {
        margin-top: 5px;
      }
      h3 {
        margin-top: 5px;
        font-size: var(--font-size--heading-small);
      }
      a {
        @include button(false);
      }
    }
  }
  @include tablet {
    grid-template-areas: 'heading' 'reason-1' 'reason-2' 'reason-3';
    grid-template-columns: auto;
    grid-template-rows: auto;
    padding: 0;
    text-align: center;
    gap: 0;
    & > app-container-block {
      app-image-block {
        margin: auto;
        max-width: 150px;
        height: 150px;
      }
      app-text-block {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        padding-top: 0;
        margin: auto;
        max-width: 500px;
        app-dynamic-link {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

// Hack to support "reasons-section" with 4 columns
.reasons-section-4 {
  padding: var(--padding--regular) calc(var(--padding--regular) * 4);
  grid-template-areas: 'heading heading heading heading' 'reason-1 reason-2 reason-3 reason-4';
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: max-content max-content;
  gap: var(--padding--medium) var(--padding--regular);
  & > app-text-block {
    grid-area: heading;
    h2 {
      font-size: var(--font-size--heading-regular);
      text-align: center;
      margin: 0;
    }
  }
  & > app-container-block {
    height: min-content;
    app-image-block {
      max-width: 200px;
      height: 200px;
    }
    app-text-block {
      padding-top: 0;
      h2 {
        margin-top: 5px;
      }
      h3 {
        margin-top: 5px;
        font-size: var(--font-size--heading-small);
      }
      a {
        @include button(false);
      }
    }
  }
  @include tablet {
    grid-template-areas: 'heading heading' 'reason-1 reason-2' 'reason-3 reason-4';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content max-content;
  }
  @include mobile {
    grid-template-areas: 'heading' 'reason-1' 'reason-2' 'reason-3' 'reason-4';
    grid-template-columns: auto;
    grid-template-rows: auto;
    padding: 0;
    text-align: center;
    gap: 0;
    & > app-container-block {
      app-image-block {
        margin: auto;
        max-width: 150px;
        height: 150px;
      }
      app-text-block {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        padding-top: 0;
        margin: auto;
        max-width: 500px;
        app-dynamic-link {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
