// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'variables';

@include mat-core();
// hue. Available color palettes: https://material.io/design/color/
$website-primary: mat-palette($mat-cyan);
$website-accent: mat-palette($mat-cyan);
$website-warn: mat-palette($mat-red);
$website-theme: mat-light-theme(
  (
    color: (
      primary: $website-primary,
      accent: $website-accent,
      warn: $website-warn,
    ),
  )
);
@include angular-material-theme($website-theme);

/* Website styling imports */
@import './styles/variables.scss';
@import './styles/mediaQueries.scss';
@import './styles/fonts.scss';

@import './styles/classes/logo-grid.scss';
@import './styles/classes/sub-sections.scss';
@import './styles/classes/reasons.scss';
@import './styles/classes/results-sections.scss';
@import './styles/classes/columns-2.scss';
@import './styles/classes/columns-3.scss';
@import './styles/classes/about-us-intro.scss';
@import './styles/classes/contact-us-intro.scss';
@import './styles/classes/what-you-get.scss';
@import './styles/classes/links-row.scss';
@import './styles/classes/case-quote.scss';
@import './styles/classes/media-background.scss';
@import './styles/classes/pricing-list.scss';
@import './styles/classes/legal-page.scss';
@import './styles/classes/compensate-plus-grid.scss';
// Common should be last one!
@import './styles/classes/misc.scss';
@import './styles/classes/common.scss';

html,
body {
  // height: 100%;
}
body {
  margin: 0;
}
* {
  font-family: 'Circular', Helvetica, Arial, sans-serif;
  outline: none;
}
h1 {
  font-size: var(--font-size--heading-hero);
}
h2,
h3 {
  font-size: var(--font-size--heading-regular);
}
h4,
h5 {
  font-size: var(--font-size--heading-small);
}
p {
  line-height: 150%;
  white-space: pre-line;
}
blockquote {
  margin: var(--padding--regular) 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
}

label {
  * {
    color: var(--form-color);
    margin: 0;
  }
}

ul {
  padding-left: 28px;
  li {
    list-style-image: url('data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2217%22%20viewBox%3D%220%200%2018%2017%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M8.15094%201L16%208.5M16%208.5L8.15094%2016M16%208.5L1.01327e-06%208.5%22%20stroke%3D%22black%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    padding-left: 5px;
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

.options-container {
  background: #000;
  padding: 10px 20px;
  border: 3px solid #fff;
  width: 250px;
  margin-top: -3px;
  max-height: 33vh;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background: #fff;
    margin: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #eee;
  }
  .option {
    color: #fff;
    cursor: pointer;
    transition: 300ms;
    padding-top: 10px;
    padding-bottom: 10px;

    &:hover {
      opacity: 0.5;
    }
  }
  &.white {
    background: #fff;
    border-color: #000;
    &::-webkit-scrollbar-thumb {
      background: #000;
    }
    .option {
      color: #222;
    }
  }
}

li {
  a {
    &::before {
      display: none !important;
    }
  }
}

.share {
  img {
    max-height: 48px;
    margin-top: var(--padding--small);
    transition: 300ms;
    &:hover {
      opacity: 0.5;
    }
  }
}

.snack {
  background: var(--colors--dark);
  border-radius: 0 !important;
  max-width: 40vw !important;
  padding: 20px 26px !important;
  * {
    font-size: 18px !important;
    color: #fff !important;
  }
  @include mobile {
    max-width: 95vw !important;
  }
}

.snack-error {
  background: var(--colors--error);
}

.cdk-overlay-container,
.cdk-overlay-connected-position-bounding-box {
  z-index: 10000000;
}
