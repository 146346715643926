@import '../mediaQueries.scss';

.pricing-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: var(--padding--regular);
  gap: var(--padding--regular);
  & > app-text-block {
    background: var(--colors--cyan);
    height: calc(
      33vw - var(--padding--regular) * 2 - var(--padding--medium) * 2
    );
    padding: var(--padding--medium);
    display: block;
    color: #fff;
    &:last-child {
      background: var(--colors--dark);
    }
    h1,
    h2,
    h3,
    h4 {
      margin-top: var(--padding--tiny);
      margin-bottom: var(--padding--tiny);
    }
    a {
      color: #fff;
    }
  }
  @include tablet {
    grid-template-columns: 1fr 1fr;
    & > app-text-block {
      height: calc(
        40vw - var(--padding--regular) * 2 - var(--padding--medium) * 2
      );
    }
  }
  @include mobile {
    grid-template-columns: 1fr;
    & > app-text-block {
      height: calc(
        100vw - var(--padding--regular) * 2 - var(--padding--medium) * 2
      );
    }
  }
}
