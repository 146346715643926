@import '../mixins.scss';
@import '../mediaQueries.scss';

.logo-grid-text {
  max-width: 520px;
  margin-left: var(--padding--large);
  h2 {
    font-size: var(--font-size--heading-regular);
    margin: 0 0 var(--padding--regular);
    margin: 0 0 var(--padding--regular);
  }
  p {
    margin: var(--padding--regular) 0;
  }
  a {
    margin: var(--padding--regular) 0 0;
    @include button(false);
  }
  @include mobile {
    margin-left: 0;
  }
}

.logo-grid {
  display: flex !important;
  max-width: 1280px;
  flex-flow: row wrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  max-width: calc(100% - var(--padding--regular) * 2);
  margin: auto;
  app-image-block {
    margin: var(--padding--small);
    img {
      max-width: 200px !important;
      max-height: 120px !important;
      height: initial !important;
      width: 15vw;
      opacity: 0.5;
      transition: 500ms ease;
      &:hover {
        opacity: 1;
      }
    }
  }
  &.grid-of-3 {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--padding--medium);
    margin: 0 var(--padding--regular) var(--padding--regular);

    @include mobile {
      gap: var(--padding--small);
    }

    app-image-block {
      margin: 0 !important;
      img {
        width: 100%;
        max-width: 150px !important;
        max-height: 150px !important;
      }
    }
  }
  @include mobile {
    margin-top: 0;
    gap: 5vw;
    app-image-block {
      img {
        width: 20vw;
      }
    }
  }
}
