@import '../mixins.scss';
@import '../mediaQueries.scss';

.links-row {
  margin: 0 var(--padding--regular) var(--padding--regular);
  background: var(--colors--cyan);
  text-align: center;
  display: block;
  color: #fff;
  section {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    h1,
    h2,
    h3,
    h4,
    h5,
    p:first-of-type {
      width: 100%;
    }
    strong,
    h3,
    h4,
    h5 {
      font-weight: 700;
      font-size: var(--font-size--heading-small);
    }
    a {
      @include button(true);
      margin: var(--padding--small) var(--padding--tiny) !important;
    }
  }

  @include mobile {
    padding: 0 var(--padding--regular) var(--padding--regular);
    margin: 0;
    section {
      a {
        margin: 10px 0 !important;
      }
    }
  }
}
