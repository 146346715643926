@import '../mediaQueries.scss';

.columns-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: var(--padding--large) 0;
  margin: var(--padding--regular);
  &.aspect-1-2 {
    grid-template-columns: 1fr 2fr;
  }
  &.aspect-2-1 {
    grid-template-columns: 2fr 1fr;
  }
  & > app-text-block,
  & > app-container-block {
    max-width: 760px;
    &:first-child {
      padding: var(--padding--regular) var(--padding--large)
        var(--padding--regular) var(--padding--extra-large);
    }
    &:last-child {
      padding: var(--padding--regular) var(--padding--extra-large)
        var(--padding--regular) var(--padding--large);
    }
    h2 {
      &:not(:last-child) {
        margin: 0 0 var(--padding--regular);
      }
    }
    h3 {
      font-size: var(--font-size--heading-small);
      margin: var(--padding--regular) 0;
      color: var(--colors--cyan);
    }
    h4 {
      font-size: var(--font-size--body-large);
      margin-bottom: 0;
      & + p {
        margin-top: 0;
      }
    }
    a {
      &:not(.not-button):not(.contact-link) {
        @include button(false);
      }
      &.contact-link {
        font-size: var(--font-size--body-large);
        color: var(--colors--cyan);
      }
    }
  }
  & > app-image-block {
    &:first-child {
      margin-right: var(--padding--medium);
    }
    &:last-child {
      margin-left: var(--padding--medium);
    }
  }

  @include mobile {
    padding: var(--padding--regular) 0;
    margin: 0;
    grid-template-columns: auto !important;
    & > app-image-block {
      margin: var(--padding--tiny) var(--padding--regular) !important;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    & > app-text-block {
      &:first-of-type {
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &:last-of-type {
        grid-row-start: 2;
        grid-row-end: 3;
      }
      &:first-child {
        padding: var(--padding--regular) var(--padding--regular) 0
          var(--padding--regular);
      }
      &:last-child {
        padding: var(--padding--regular) var(--padding--regular);
      }
      h2:not(:last-child) {
        margin: 0 0 10px;
      }
      h3 {
        margin: var(--padding--tiny) 0;
      }
    }
  }
}
