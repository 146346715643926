@import '../mediaQueries.scss';
@import '../mixins.scss';
app-text-block {
  &.store-images-row {
    p {
      display: block;
    }
    img {
      display: inline-block;
      max-width: 200px;
      width: 35%;
    }
    .not-button {
      padding: var(--padding--small) var(--padding--small) var(--padding--small)
        0;
    }
    @include mobile {
      img {
        width: 55%;
        margin-bottom: 5px;
      }
      .not-button {
        padding: var(--padding--tiny) var(--padding--tiny) var(--padding--tiny)
          0;
      }
    }
  }
  &.arrow-buttons {
    a {
      @include arrow-button();
    }
    &--bold {
      a {
        @include arrow-button();
        font-weight: 700;
      }
    }
  }
  &.button-links {
    a {
      @include button(false);
    }
    &-white {
      a {
        @include button(true);
      }
    }
  }
}

.navigation-invert {
  header {
    .logo {
      filter: invert(1);
    }

    .app-nav-buttons {
      .app-nav-button {
        color: #000 !important;
      }
    }

    .menu {
      --menu-color: #000 !important;
    }
  }
}

.navigation-padding {
  main {
    padding-top: var(--padding--extra-large);
  }
}

.navigation-small {
  header {
    height: 48px !important;
    top: var(--padding--regular) !important;
    left: var(--padding--regular) !important;
    right: var(--padding--regular) !important;
    --logo-height: 48px;
    .logo {
      max-height: 48px !important;
    }

    .menu {
    }
  }
}

.in-modal {
  input {
    text-align: center;
    font-size: 16px !important;
    padding: 12px !important;
    border-color: #fff !important;
    &::placeholder {
      text-align: center;
      color: #000;
    }
  }
}
