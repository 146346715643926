@import './mediaQueries.scss';

@mixin button($white: true, $large: false) {
  @if $white {
    --button-color: #fff;
  } @else {
    --button-color: #1b1b1b;
  }
  color: var(--button-color);
  border: 3px solid var(--button-color);
  display: flex;
  width: max-content;
  margin: var(--padding--regular) 0 0;
  text-decoration: none;
  font-weight: 600;
  transition: 300ms;
  background: none;
  outline: none;
  cursor: pointer;
  @if $large {
    padding: 18px 28px;
    font-size: var(--font-size--button-large);
  } @else {
    padding: 16px 20px;
    font-size: var(--font-size--button-regular);
  }
  &:hover {
    @if $white {
      --button-color: rgb(255, 255, 255, 0.8);
    } @else {
      --button-color: #4f4f4f;
    }
  }
  &:active {
    @if $white {
      --button-color: rgb(255, 255, 255, 0.6);
    } @else {
      --button-color: rgba(79, 79, 79, 0.6);
    }
  }
  @include mobile() {
    margin-top: var(--padding--small);
    border: 3px solid var(--button-color);
    @if $large {
      padding: 15px 24px;
    } @else {
      padding: 13px 18px;
    }
  }
}

@mixin arrow-button($white: false) {
  border: none !important;
  display: flex !important;
  align-items: center;
  transition: 300ms ease-out;
  padding: 5px 0 !important;
  margin: var(--padding--tiny) 0 !important;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    transform: translateX(8px);
  }

  &::before {
    @if $white {
      content: url(/assets/icons/arrow-white-to-right.svg);
    } @else {
      content: url(/assets/icons/arrow-black-to-right.svg);
    }
    min-width: 42px;
    margin-right: 20px;
    display: block;
    line-height: 0;
    margin-top: -2px;
  }
}
