@import '../mediaQueries.scss';

.legal-page {
  header {
    .logo {
      filter: invert(1);
    }
    .app-nav-buttons {
      .app-nav-button {
        color: #000;
      }
    }
    .menu {
      --menu-color: #000 !important;
    }
  }

  main {
    padding: var(--padding--extra-large) var(--padding--large);
  }

  @include mobile {
    main {
      padding: var(--padding--extra-large) 0;
    }
  }
}
