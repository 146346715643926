@import '../mixins.scss';
@import '../mediaQueries.scss';

.sub-sections-1 {
  display: grid;
  grid-template-rows:
    repeat(31, var(--padding--regular)) max-content var(--padding--large)
    max-content;
  grid-template-columns: auto;
  padding: var(--padding--regular);
  & > * {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  & > app-image-block:first-of-type {
    grid-row-start: 1;
    grid-row-end: 32;
    height: 100%;
    z-index: 0;
  }
  app-text-block {
    z-index: 1;
    & > p {
      @include desktop-small {
        max-width: 420px;
      }
      @include tablet-only {
        max-width: 360px;
      }
    }
    &:first-of-type {
      grid-row-start: 5;
      margin-left: var(--padding--large);
      max-width: 560px;
      @include desktop-small {
        max-width: 420px;
      }
      @include tablet-only {
        max-width: 360px;
      }
      width: 50vw;
      h2 {
        font-size: var(--font-size--heading-regular);
        margin: 0 0 var(--padding--regular);
      }
      h3 {
        font-size: var(--font-size--heading-small);
        margin: var(--padding--regular) 0;
        color: var(--colors--cyan);
      }
    }
    a {
      @include button(false);
    }
  }
  .sub-section-regular {
    margin-left: var(--padding--large);
    grid-template-columns: max-content max-content;
    grid-template-rows: auto;
    grid-template-areas: 'image text';
    app-image-block {
      grid-area: image;
      max-width: 560px;
      width: 27.5vw;
    }
    app-text-block {
      grid-area: text;
    }
    &:first-of-type {
      grid-row-start: 30;
      grid-row-end: 33;
      app-text-block {
        margin-top: calc(var(--padding--regular) * 5);
        align-self: end;
      }
    }
    &:last-of-type {
      grid-row-start: 34;
      grid-row-end: 35;
      app-text-block {
        align-self: start;
      }
    }
  }
  @include mobile {
    grid-template-columns: auto;
    grid-template-rows: auto;
    gap: var(--padding--tiny);
    app-image-block:first-of-type {
      grid-row-start: 1;
      grid-row-end: 2;
    }
    app-text-block {
      &:first-of-type {
        grid-row-start: 2;
        grid-row-end: 3;
        width: 100%;
        margin-left: 0;
        padding: 0;
        margin-top: 0;
      }
      &:not(:last-of-type) {
        padding-bottom: var(--padding--regular);
      }
      h2 {
        margin: 0 0 var(--padding--tiny) !important;
      }
      h3 {
        margin: var(--padding--tiny) 0 !important;
      }
      app-dynamic-link {
        margin-bottom: var(--padding--regular);
      }
    }
    .sub-section-regular {
      grid-template-columns: auto;
      grid-template-areas: 'image' 'text';
      margin-left: 0;
      app-image-block {
        margin-top: var(--padding--regular);
        width: auto;
      }
      &:first-of-type {
        grid-row-start: 3;
        grid-row-end: 4;
        app-text-block {
          align-self: start;
          margin-top: var(--padding--tiny);
        }
      }
      &:last-of-type {
        grid-row-start: 4;
        grid-row-end: 5;
        app-text-block {
          align-self: start;
          margin-top: var(--padding--tiny);
        }
      }
    }
  }
}
